const TYAThemeConfig = {
  name: 'TYATheme',
  prefix: 'tyaTheme',
  rootStyle: {
    '--GW-BRAND-COLOR-1': '#FF0022',
    // toyota red
    '--GW-BRAND-COLOR-2': '#282830',
    // toyota grey
    '--GW-BRAND-COLOR-3': '#d3d3d3',
    // toyota grey1
    '--GW-TEXT-COLOR-1': '#1e2a32',
    '--GW-FONT-SIZE-H2': '1.6rem',
    '--GW-FONT-SIZE-H2-BOTTOM-MARGIN': '20px',
    '--GW-FONT-SIZE-H4': '1.1rem',
    '--GW-FONT-SIZE-BODY': '1.1rem',
    '--ACCORDION-HEADER-FOCUSED-BORDER': 'none',
    '--ACCORDION-HEADER-FOCUSED-BOX-SHADOW': 'transparent',
    '--GW-ACCORDION-CARDTITLE-FONT-SIZE': '1rem',
    '--GW-FONT-SIZE-LABEL': '1rem',
    '--GW-FONT-SECTION-TITLES': '1.8rem',
    '--GW-FONT-SIZE-LABEL-WEIGHT': 'normal',
    '--GW-TEXT-COLOR-2': 'var(--GW-BRAND-COLOR-2)',
    '--GW-TEXT-COLOR-3': '#fff',
    '--GW-MODAL-HEADER-TITLE-WEIGHT': 'normal',
    '--GW-TOOLTIP-COLOR-3': 'var(--GW-BRAND-COLOR-1)',
    '--GW-FONT-FAMILY': '"ToyotaType", sans-serif',
    '--GW-SELECTOR-SELECTED-BACKGROUND-COLOR': 'var(--GW-BRAND-COLOR-1)',
    // radio button selected
    '--GW-RADIO-BUTTON-SECONDARY-COLOR': '#EFF0F0',
    '--GW-BUTTON-BORDER-RADIUS': '50px',
    '--GW-BUTTON-FILLED--COLOR': '#fff',
    '--GW-BUTTON-FILLED-BACKGROUND-COLOR': 'var(--GW-BRAND-COLOR-1)',
    '--GW-BUTTON-FILLED-BACKGROUND-COLOR-HOVER': 'var(--GW-BRAND-COLOR-2)',
    '--GW-INPUT-OPACITY-DISABLED': '95%',
    '--gw-footer-padding': '40px 40px 30px',
    '--GW-BACKGROUND-COLOR': '#fff',
    '--gw-header-branding-image': './../../../applications/quote-and-buy/public/branding/tya/toyota-white-logo.svg',
    '--gw-header-branding-container-height': '90px',
    '--gw-header-branding-image-width': '280px',
    '--gw-header-branding-image-height': '50px',
    '--gw-header-branding-max-width': '1440px',
    '--gw-stepper-branding-title-disabled': '#949498',
    '--gw-stepper-branding-title-size': '1rem',
    '--gw-stepper-branding-title-font-weight': '500',
    '--gw-stepper-branding-marker-color': '#000',
    '--gw-stepper-branding-progress-marker-dimensions': '1.7rem',
    '--gw-stepper-branding-title-line-height': '1.2rem',
    '--gw-stepper-section-seperator': '#999',
    '--gw-required-branding-asterisk-color': '#B94A48',
    '--gw-button-branding-font-weight': '200',
    '--gw-button-branding-width': '190px',
    '--gw-addon-branding-border-colour': '#dedede',
    '--gw-your-branding-quote-box-border': '#d3d3d3',
    '--gw-tooltip-font-style': 'italic',
    '--gw-tooltip-font-weight': '100'
  },
  internalTheme: !0
};
export default TYAThemeConfig;