import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import { consumerThemeConfig } from "./consumer/config";
import { flaineThemeConfig } from "./flaine/config";
import TYAThemeConfig from "./TYA/config";
var defaultThemeConfig = {
  name: "Enterprise"
};
export var themeList = [defaultThemeConfig, consumerThemeConfig, flaineThemeConfig, TYAThemeConfig];
export var themeNameList = _mapInstanceProperty(themeList).call(themeList, function (theme) {
  return theme.name;
});
export default themeList;
export { defaultThemeConfig, consumerThemeConfig, flaineThemeConfig, TYAThemeConfig };